<template>
    <div class="uk-grid uk-grid-small" data-uk-grid>
        <form @submit.prevent="newUser()">
            <div class="uk-grid-match uk-child-width-1-1@s" uk-grid>
                <div>
                    <div class="uk-card-default uk-card-body">
                        <h3 class="uk-card-title">{{"NewUser" | localize}}</h3>
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label">{{"Login" | localize}}</label>
                                <input class="uk-input" type="text" placeholder="" required v-model="login" @blur="checkLogin(login)" :class="checkedLogin">
                            </div>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label">{{"Phone" | localize}}</label>
                                <input class="uk-input" type="text" placeholder="" required pattern="\d+" v-model="tel" @blur="checkPhone(tel)" :class="checkedTel">
                            </div>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label">{{"FIO" | localize}}</label>
                                <input class="uk-input" type="text" placeholder="" required v-model="FIO">
                            </div>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label">{{"Role" | localize}}</label>
                                <select class="uk-select" required v-model="role">
                                    <option value="1">{{"Администратор" | localize}}</option>
                                    <option value="2">{{"Сотрудник 1 категории" | localize}}</option>
                                    <option value="3">{{"Сотрудник 2 категории" | localize}}</option>
                                    <option value="4">{{"Модератор" | localize}}</option>
                                    <option value="5">{{"Австралия" | localize}}</option>
                                    <option value="6">{{"LME Австралия" | localize}}</option>
                                </select>
                            </div>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label">{{"Email" | localize}}</label>
                                <input class="uk-input" type="email" placeholder="" v-model='email'>
                            </div>
                            <div class="uk-width-1-3@s">
                                <label class="uk-form-label ">{{"Filial" | localize}}</label>
                                <select class="uk-select" v-model="selectedFilial">
                                    <option v-for="filial in filials" v-bind:value="filial">
                                        {{ filial.text }}
                                    </option>
                                </select>
                            </div>
                             <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                                <label class="uk-form-label ">{{"Pt" | localize}}</label>
                                <input class="uk-input" type="text" v-model='pt'>
                            </div>
                            <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                                <label class="uk-form-label ">{{"Pd" | localize}}</label>
                                <input class="uk-input" type="text" v-model='pd'>
                            </div>
                            <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                                <label class="uk-form-label ">{{"Rh" | localize}}</label>
                                <input class="uk-input" type="text" v-model='rh'>
                            </div>
                        </div>
                    </div>
                    <button class="uk-button uk-button-primary uk-width-1-3@m uk-width-1-1@s uk-align-center uk-margin-bottom"> {{"Save" | localize}} </button>
                </div>
            </div>
        </form>
        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;" v-if="results.length != 0">
            <thead>
                <tr>
                    <th class="uk-table-shrink">#</th>
                    <th class="">{{"Login" | localize}}</th>
                    <th class="">{{"FIO" | localize}}</th>
                    <th class="">{{"Email" | localize}}</th>
                    <th class="">{{"Phone" | localize}}</th>
                    <th class="">{{"Role" | localize}}</th>
                </tr>
            </thead>
            <tbody v-for="(result, cnt) in results">
                <tr>
                    <td>{{cnt + 1}}</td>
                    <td>{{result.login || '-'}}</td>
                    <td>{{result.FIO || '-'}}</td>
                    <td>{{result.email || '-'}}</td>
                    <td class="uk-text-nowrap">{{result.tel || '-'}}</td>
                    <td class="uk-text-nowrap">{{'role' + result.role | localize}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

// TODO: проверка логина email!!!!!!

import newUser from '@/config/NewUser.dashboard'
import checkLogin from '@/config/checkLogin.dashboard'
import checkPhone from '@/config/checkPhone.dashboard'
import getFilials from '@/config/GetFilials.dashboard'

export default {
    data:() => ({
        login: '',
        tel: '',
        FIO: '',
        role: '',
        email: '',
        results: [],
        checkedLogin: '',
        checkedTel: '',
        filials: [],
        selectedFilial: '',
        pt: 70,
        pd: 65,
        rh: 60
    }),
    methods:{
        newUser, checkLogin, checkPhone, getFilials
    },
    beforeMount(){
        this.getFilials();
    }
}
</script>
