import localizeFilter from '@/filter/localize.filter'
export default function checkLogin(login){
    if(login != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 9,
                    login: login
                }
            })
            .then(response => {
                if(response.data != -10){
                    if(response.data == '0'){
                        this.$data.checkedLogin = 'uk-form-success'
                    }else{
                        this.$data.checkedLogin = 'uk-form-danger'
                        UIkit.notification({message: localizeFilter('checkLogin'), pos:'bottom-center'})
                    }
                    
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}