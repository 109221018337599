import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function newUser(){
    this.$Progress.start()
    axios.get('/dashboard.php',
        {
            params:
            {
                type: 8,
                login: this.login,
                role: this.role,
                FIO: this.FIO,
                email: this.email,
                tel: this.tel,
                filial: this.selectedFilial.value,
                pt: this.pt,
                pd: this.pd,
                rh: this.rh
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.results.push(
                    {
                        login: this.login,
                        role: this.role,
                        FIO: this.FIO,
                        email: this.email,
                        tel: this.tel,
                        filial: this.selectedFilial.value
                    }
                )
                Journal(localStorage.getItem('login'),
                        'Добавлен новый пользователь: ' + this.login +
                        '. Телефон: ' + this.tel +
                        '. ФИО: ' + this.FIO +
                        '. Роль: ' + this.role +
                        '. E-mail: ' + this.email +
                        '. Филиал: ' + this.selectedFilial.text
                        )
                this.login = ''
                this.role= ''
                this.FIO= ''
                this.email= ''
                this.tel= ''
                this.selectedFilial = ''
                UIkit.notification({message: localizeFilter('NewUserCreated')})
            }else{
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            }
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}
